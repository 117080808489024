import utils from './utils'

/* Top Loading */
document.addEventListener('DOMContentLoaded', () => {
  if (utils.isTop()) {
    const $imgs = document.querySelectorAll('img')
    const $progress: HTMLElement | null = document.querySelector(
      '.c-loading__bar__progress'
    )
    const { length } = $imgs
    let count = 0
    const handleImgLoad = () => {
      count += 1
      if ($progress === null) return
      const percent = (count / length) * 100
      $progress.style.width =
        percent < 33 ? '66%' : percent < 66 ? '33%' : `${100 - percent}%`

      if (length === count) {
        document.body.classList.add('is-loaded')
        $progress.style.width = '0%'
      }
    }
    if ($imgs) {
      Array.prototype.slice.call($imgs, 0).forEach(img => {
        img.addEventListener('load', handleImgLoad)
      })
    }

    window.addEventListener('load', () => {
      if ($progress === null) return
      $progress.style.width = '0%'
      setTimeout(() => {
        document.body.classList.add('is-loaded')
      }, 1000)
    })
  }
})
