export default class Cookie {
  static has(key: string) {
    return document.cookie.indexOf(key) !== -1
  }

  static set(key: string, value: string, expireDay?: number) {
    if (typeof expireDay === 'undefined') {
      document.cookie = `${key}=${value};`
    } else {
      const expire = new Date()
      expire.setTime(expire.getTime() + 1000 * 60 * 60 * 24 * expireDay)
      document.cookie = `${key}=${value}; expires=${expire.toUTCString()}`
    }
  }

  static remove(key: string) {
    const expire = new Date()
    expire.setTime(expire.getTime() - 1)
    document.cookie = `${key}=; expires=${expire.toUTCString()}`
  }
}
