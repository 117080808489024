import utils from './utils'
import _ from 'lodash'
import Cookie from './Cookie'

/* Top Opening Animation */
document.addEventListener('DOMContentLoaded', () => {
  const COOKIE_ENABLE = true
  const KEY = 'OPENING_COMPLETED'

  if (utils.isTop()) {
    if (COOKIE_ENABLE && Cookie.has(KEY)) {
      document.body.classList.add('is-opening-disable')
      return
    }

    window.addEventListener('load', () => {
      document.body.classList.add('is-opening-started')
      setTimeout(() => {
        document.body.classList.add('is-opening-ended')
        if (COOKIE_ENABLE) {
          Cookie.set(KEY, 'true', 1)
        }
      }, 8 * 1000)
    })

    /* Top KV Ratio */
    const handleResize = () => {
      const { innerWidth, innerHeight } = window
      const ratio = innerWidth / innerHeight
      if (ratio > 0.65) {
        document.body.classList.add('is-wide')
      } else {
        document.body.classList.remove('is-wide')
      }
    }
    handleResize()
    window.addEventListener('resize', _.debounce(handleResize, 100))
  }
})
