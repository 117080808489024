import jump from 'jump.js'

const handleClick = (hash: string, isAnim: boolean = true): void => {
  const selector: string = `#${hash.replace('#anchor-', '')}`
  const $elm: HTMLElement | null = document.querySelector(selector)
  const $header: HTMLElement | null = document.querySelector('.l-header')
  if ($elm && $header) {
    const { isOffsetHeader } = $elm.dataset
    const { clientHeight } = $header
    jump(selector, {
      duration: isAnim ? 500 : 0,
      offset: isOffsetHeader ? (clientHeight > 100 ? -58 : -clientHeight) : 0,
    })
  }
}

window.addEventListener('load', () => {
  const $anchorNodes: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
    'a[href^="#anchor-"]'
  )
  const $anchors: Array<HTMLAnchorElement> = Array.prototype.slice.call(
    $anchorNodes,
    0
  )
  const hash: string = location.hash

  if (hash.indexOf('#anchor-') !== -1) {
    if (hash.indexOf('#anchor-no-anim-') !== -1) {
      handleClick(hash.replace('#anchor-no-anim-', ''), false)
    } else {
      handleClick(hash.replace('#anchor-', ''))
    }
  }

  $anchors.forEach(anchor => {
    anchor.addEventListener('click', e => {
      e.preventDefault()
      const { dataset, hash } = anchor
      const { scrollto } = dataset
      if (scrollto) {
        document.body.dataset.currentSection = scrollto
      }
      if (hash.indexOf('#anchor-no-anim-') !== -1) {
        handleClick(hash.replace('#anchor-no-anim-', ''), false)
      } else {
        handleClick(hash.replace('#anchor-', ''))
      }
    })
  })
})
