import jump from 'jump.js'

/* Accordion */
document.addEventListener('DOMContentLoaded', () => {
  const $accordionNodes: NodeListOf<
    HTMLAnchorElement
  > = document.querySelectorAll('[data-type="accordion"]')
  const $accordions: Array<HTMLAnchorElement> = Array.prototype.slice.call(
    $accordionNodes,
    0
  )

  const open = (
    $container: HTMLElement,
    $panel: HTMLElement,
    $panelInner: HTMLElement
  ) => {
    const { scrollHeight } = $panelInner
    $panel.style.height = `${scrollHeight}px`
    $container.dataset.expanded = 'true'
  }

  const close = ($container: HTMLElement, $panel: HTMLElement) => {
    $panel.style.height = null
    $container.dataset.expanded = 'false'
  }

  const toggle = (
    $container: HTMLElement,
    $panel: HTMLElement,
    $panelInner: HTMLElement
  ) => {
    const expanded = $container.dataset.expanded === 'true'
    expanded ? close($container, $panel) : open($container, $panel, $panelInner)
  }

  $accordions.forEach($container => {
    const $trigger: HTMLElement | null = $container.querySelector(
      '[data-action="toggle-accordion"]'
    )
    const $closeBtn: HTMLElement | null = $container.querySelector(
      '[data-action="close-accordion"]'
    )
    const $panel: HTMLElement | null = $container.querySelector(
      '[data-type="accordion-panel"]'
    )
    const $panelInner: HTMLElement | null = $container.querySelector(
      '[data-type="accordion-panel-inner"]'
    )
    if (!$trigger || !$panel || !$panelInner) return
    $trigger.addEventListener('click', () => {
      toggle($container, $panel, $panelInner)
    })

    if (!$closeBtn) return
    $closeBtn.addEventListener('click', () => {
      close($container, $panel)
      const $header: HTMLElement | null = document.querySelector('.l-header')
      jump($container, {
        duration: 300,
        offset: $header ? -$header.clientHeight : 0,
      })
    })
  })
})
