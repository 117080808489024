interface State {
  opened: boolean
}

class Drawer {
  state: State = {
    opened: false,
  }
  $body: HTMLElement | null = null
  $menuBtn: HTMLAnchorElement | null = null
  $closeBtn: HTMLAnchorElement | null = null
  $overlay: HTMLAnchorElement | null = null
  additionalName: string = 'is-opened--drawer-menu'

  init = (): void => {
    this.$menuBtn = document.querySelector<HTMLAnchorElement>(
      '#js-hamburger-btn'
    )
    this.$overlay = document.querySelector<HTMLAnchorElement>('#js-overlay')
    this.$body = document.body as HTMLElement
    if (this.$menuBtn) {
      this.$menuBtn.addEventListener('click', this.toggle)
    }
    if (this.$overlay) {
      this.$overlay.addEventListener('click', this.close)
    }
  }

  _update = (): void => {
    const { opened } = this.state
    if (this.$body) {
      if (opened) {
        this.$body.classList.add(this.additionalName)
      } else {
        this.$body.classList.remove(this.additionalName)
      }
    }
  }

  open = (): void => {
    this.state.opened = true
    this._update()
  }

  close = (): void => {
    this.state.opened = false
    this._update()
  }

  toggle = (): void => {
    const { opened } = this.state
    this.state.opened = !opened
    this._update()
  }
}

const drawer = new Drawer()

export default drawer
