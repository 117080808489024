import ScrollMagic from 'scrollmagic'
import utils from './utils'

document.addEventListener('DOMContentLoaded', () => {
  const headerController = new ScrollMagic.Controller()
  const pageupController = new ScrollMagic.Controller()
  const reverse = false

  /* header*/
  new ScrollMagic.Scene({
    triggerElement: 'body',
    triggerHook: 0,
    offset: 1,
  })
    .setClassToggle('#Header', '-fixed')
    .addTo(headerController)

  /* pageup */
  new ScrollMagic.Scene({
    triggerElement: 'body',
    triggerHook: 0,
    offset: 200,
  })
    .setClassToggle('body', '-visible-pageup')
    .addTo(pageupController)

  new ScrollMagic.Scene({
    triggerElement: '.l-footer',
    triggerHook: 1,
  })
    .setClassToggle('#js-pageup', '-static')
    .addTo(pageupController)

  if (utils.isTop()) {
    const topScrollController = new ScrollMagic.Controller()
    new ScrollMagic.Scene({
      triggerElement: '#QuiHeaderTtl',
      triggerHook: 0.85,
      reverse,
    })
      .setClassToggle('#QuiHeaderTtl', '-active')
      .addTo(topScrollController)

    new ScrollMagic.Scene({
      triggerElement: '#QuiHeaderTxt',
      triggerHook: 0.85,
      reverse,
    })
      .setClassToggle('#QuiHeaderTxt', '-active')
      .addTo(topScrollController)

    new ScrollMagic.Scene({
      triggerElement: '#QuiHeaderLink',
      triggerHook: 0.85,
      reverse,
    })
      .setClassToggle('#QuiHeaderLink', '-active')
      .addTo(topScrollController)

    new ScrollMagic.Scene({
      triggerElement: '#QuiImgGrid',
      triggerHook: 0.85,
      reverse,
    })
      .setClassToggle('#QuiImgGrid', '-active')
      .addTo(topScrollController)

    new ScrollMagic.Scene({
      triggerElement: '#ServiceHeaderTtl',
      triggerHook: 0.85,
      reverse,
    })
      .setClassToggle('#ServiceHeaderTtl', '-active')
      .addTo(topScrollController)

    new ScrollMagic.Scene({
      triggerElement: '#Visa',
      triggerHook: 0.85,
      reverse,
    })
      .setClassToggle('#Visa', '-active')
      .addTo(topScrollController)

    new ScrollMagic.Scene({
      triggerElement: '#Ecole',
      triggerHook: 0.85,
      reverse,
    })
      .setClassToggle('#Ecole', '-active')
      .addTo(topScrollController)

    new ScrollMagic.Scene({
      triggerElement: '#Traduction',
      triggerHook: 0.85,
      reverse,
    })
      .setClassToggle('#Traduction', '-active')
      .addTo(topScrollController)
  }
})
