import '../scss/app.scss'
import drawer from './Drawer'
import './jump.setting'
import './scrollmagic.setting'
import './opening.setting'
import './loading.setting'
import './drawer-accordion.setting'
import './accordion.setting'
import './curtain.setting'

document.addEventListener('DOMContentLoaded', () => {
  drawer.init()
})
