document.addEventListener('DOMContentLoaded', () => {
  const html = `
<div id="c-curtain__item--01" class="c-curtain__item"></div>
<div id="c-curtain__item--02" class="c-curtain__item"></div>
<div id="c-curtain__item--03" class="c-curtain__item"></div>
<div id="c-curtain__item--04" class="c-curtain__item"></div>
<div id="c-curtain__item--05" class="c-curtain__item"></div>
`
  const $curtain = document.querySelector('#Curtain')
  if (!$curtain) return
  $curtain.insertAdjacentHTML('afterbegin', html)
})
