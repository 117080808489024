/* drawer-accordion setting */
document.addEventListener('DOMContentLoaded', () => {
  const $accordion: HTMLElement | null = document.querySelector(
    '#js-drawer-menu__accordion'
  )
  const triggerNodeList = document.querySelectorAll(
    '.c-drawer-menu__accordion__link'
  )
  const $triggers: Array<HTMLAnchorElement> = Array.prototype.slice.call(
    triggerNodeList,
    0
  )

  $triggers.forEach(trigger => {
    trigger.addEventListener('click', (e: Event) => {
      e.preventDefault()
      // @ts-ignore
      const { accordionName } = e.target.dataset
      if (accordionName && $accordion) {
        // @ts-ignore
        const { accordionExpandedName: prevName } = $accordion.dataset
        $accordion.dataset.accordionExpandedName =
          prevName === accordionName ? '' : accordionName
      }
    })
  })
})
